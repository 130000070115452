<template>
  <v-container v-if="team" fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="4" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
        <team-profile-side-bar-professional v-if="$auth.granted('role_professionals')" :team="team" />
        <team-profile-side-bar-patient v-else :team="team" />
      </v-col>
      <v-col cols="12" xl="6" lg="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
        <team-profile-header-content :team="team" />
        <vue-page-transition name="fade-in-down">
          <router-view :key="$route.fullPath" :team="team" />
        </vue-page-transition>
      </v-col>
    </v-row>
  </v-container>
  <page-loader v-else />
</template>

<script>
  import TeamProfileSideBarProfessional from '@/modules/teams/components/professionals/TeamProfileSideBar'
  import TeamProfileSideBarPatient from '@/modules/teams/components/patients/TeamProfileSideBar'
  import TeamProfileHeaderContent from '@/modules/teams/components/TeamProfilHeaderContent'
  import PageLoader from '@/modules/core/layout/PageLoader'
  import {Team} from '@/models'

  export default {
    name: 'TeamProfileSection',
    components: {TeamProfileSideBarProfessional,TeamProfileSideBarPatient, PageLoader, TeamProfileHeaderContent},
    computed: {
      team() {
        return Team.get(this.$route.params.uid)
      }
    },
    created() {
      Team.fetchOne(this.$route.params.uid)
    }
  }
</script>

