<template>
  <v-card class="elevation-x mb-7">
    <v-list-item inactive two-line>
      <v-list-item-avatar :width="$vuetify.breakpoint.mdAndDown ? '60px' : '80px'" :height="$vuetify.breakpoint.mdAndDown ? '60px' : '80px'" class="mt-0">
        <font-awesome-icon :icon="['fad', 'users']" :size="$vuetify.breakpoint.mdAndDown ? '3x' : '4x'" class="primary--text" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-md-h4 text-h5 font-alt font-weight-thin">{{ team.name }}</v-list-item-title>
        <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-thin font-alt text-md-h6 text-subtitle-1">
          <base-date :date="team.creation_date" not-relative format="LL" />
        </v-list-item-subtitle>
        <div class="actions-list">
          <v-chip v-if="team.status === 'active'" small class="my-2 pl-0 mr-2 font-alt" color="primary">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fal', 'check']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            Actif
          </v-chip>
          <v-chip v-else small class="my-2 pl-0 mr-2 font-alt" color="grey lighten-2">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fal','lock']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            Cloturé
          </v-chip>
          <v-chip small class="my-2 pl-0 mr-2 font-alt" color="primary">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fal', 'user']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            {{ team.total_members }} {{ 'membre'|pluralize(team.total_members) }}
          </v-chip>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider v-if="team.description" />
    <v-row v-if="team.description" class="ma-0">
      <v-col cols="12" class="py-2">
        <v-list-item-subtitle class="pl-1 font-alt">Description :</v-list-item-subtitle>
        <div class="caption pl-1">{{ team.description || 'Non renseigné' }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import BaseDate from '@/modules/core/components/BaseDate'
  export default {
    name: 'TeamProfilHeaderContent',
    components: {BaseDate},
    props: {
      team: {
        type: Object,
        required: false
      }
    }
  }
</script>
