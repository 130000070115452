<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card flat>
    <v-navigation-drawer width="100%">
      <v-list-item inactive two-line>
        <v-list-item-avatar tile>
          <font-awesome-icon fixed-width size="2x" :icon="['fad', 'users']" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ team.name }}</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">
            Fiche groupe
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item link :to="{name:'my-teams'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'chevron-left']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Retour à la liste</v-list-item-title>
        </v-list-item>
        <v-subheader class="pl-2">Informations</v-subheader>
        <v-list-item :to="{name: 'team-profile'}" link exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Publications</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'team-members'}" link exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Membres</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item :to="{name: 'team-historic'}" link exact>-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'history']" class="grey&#45;&#45;text text&#45;&#45;darken-1" />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Historique</v-list-item-title>-->
        <!--        </v-list-item>-->
        <v-divider v-if="team.status === 'active'" />
        <v-list-item-title v-if="team.status === 'active'" class="my-3 px-2 text-uppercase font-weight-light font-alt text-body-2">
          Actions
        </v-list-item-title>
        <v-list-item v-if="team.status === 'active'" class="mt-2" @click="openTeamInvite">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-md-chat']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Inviter des utilisateurs</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$can('update', team)" link exact class="mt-2" @click="openTeamEdit">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Modifier le groupe</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$can('close', team)" link exact @click="close">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-times']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Cloturer le groupe</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$can('transfer_ownership', team)" link exact class="mt-2" @click="transferOwnership">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-md-chat']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Transferer la propriété</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="$can('leave', team)" link exact class="mt-2" @click="leave">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fas', 'times']"
                               class="grey--text text--darken-1"
            />
          </v-list-item-icon>
          <v-list-item-title>Quitter le groupe</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  export default {
    name: 'TeamProfileSideBar',
    props: {
      team: {
        type: Object,
        required: true
      }
    },
    methods: {
      openTeamInvite() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/teams/views/TeamInvite'),
          props: {uid: this.team.uid}
        })
      },
      openTeamEdit() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/teams/components/TeamForm'),
          props: {teamUid: this.team.uid}
        })
      },
      async close() {
        if (!await this.$confirm('Êtes vous sûr de vouloir clôturer ce groupe ?')) return
        try {
          await this.team.update({
            status: 'closed'
          })
          await this.$router.push({name: 'teams'})
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le groupe n\'a pas pu être cloturé.'
          })
          throw e
        } finally {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Groupe cloturé',
            subtitle: 'Groupe cloturé avec succès.'
          })
        }
      },
      async transferOwnership() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/teams/components/TeamTransferOwnershipDialog'),
          props: {team: this.team}
        })
      },
      async leave() {
        if (!await this.$confirm('Êtes vous sûr de vouloir quitter ce groupe ?')) return

        try {
          await this.team.leave()
          await this.$router.push({name: 'teams'})
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le groupe n\'a pas pu être quitté.'
          })

          throw e
        } finally {
          await this.$router.push({name: 'teams'})
        }
      }
    }
  }
</script>
